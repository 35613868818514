import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCarousel from 'vue-carousel'

Vue.use(VueCarousel)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title

  const reqAuth = to.matched.some(record => record.meta.requiresAuth)
  const reqVisitor = to.matched.some(record => record.meta.requiresVisitor)

  const reqAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const reqChapelManager = to.matched.some(record => record.meta.requiresChapelManager)
  const reqChapelStaff = to.matched.some(record => record.meta.requiresChapelStaff)

  const loginQuery = { path: '/' }
  const dashboard = { path: '/dashboard' }
  const not_found = { path: '/error-404' }

  if (store.getters['authentication/authenticated']) {
    if (reqAuth) {
      var proceed = false
      if (reqAdmin && reqChapelManager) {
        if (
          store.getters['authentication/position'] === 'ADMIN' ||
          store.getters['authentication/position'] === 'ADMIN SPECIAL' ||
          store.getters['authentication/position'] === 'CHAPEL MANAGER'
        ) {
          proceed = true
        } else {
          proceed = false
        }
      } else if (reqAdmin) {
        if (
          store.getters['authentication/position'] === 'ADMIN SPECIAL' ||
          store.getters['authentication/position'] === 'ADMIN'
        ) {
          proceed = true
        } else {
          proceed = false
        }
      } else if (reqChapelManager) {
        if (store.getters['authentication/position'] === 'BRANCH MANAGER') {
          proceed = true
        } else {
          proceed = false
        }
      }

      if (reqChapelStaff) {
        if (store.getters['authentication/position'] === 'CHAPEL STAFF') {
          proceed = true
        } else {
          proceed = false
        }
      }
      if (!reqAdmin && !reqChapelManager && !reqChapelStaff) {
        proceed = true
      }
      if (proceed) {
        next()
      } else {
        next(not_found)
      }
    } else if (reqVisitor) {
      next(dashboard)
    } else {
      next()
    }
  } else {
    if (reqAuth) {
      next(loginQuery)
    } else {
      next()
    }
  }
})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch (error.response.status) {
      case 401: // Not logged in
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        if (store.getters['authentication/authenticated']) {
          store.commit('authentication/set_authenticated', false)
          store.dispatch(
            'authentication/logout_credentials',
            { id_no: store.getters['authentication/id_no'] },
            { root: true },
          )
        }
        router.push({ path: '/' })
        break
      case 500:
        return Promise.reject(error)
        break
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error)
    }
  },
)
