import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import transaction_months from './module/data/transaction_months'
import deceased_client from './module/data/deceased_client'
import casket_inventory from './module/data/casket_inventory'
import chapel_viewing_type from './module/data/chapel_viewing_type'
import staff from './module/data/staff'
import kaagapay from './module/data/kaagapay'
import bank_depository from './module/data/bank_depository'
import deposit_slip from './module/data/deposit_slip'
import payments from './module/data/payments'
import service_payment from './module/data/service_payment'
import series_kmf from './module/data/series_kmf'
import kaagapay_payment from './module/data/kaagapay_payment'
import casket_factory from './module/data/casket_factory'
import request_casket from './module/data/request_casket'
import report_casket from './module/data/report_casket'
import branch_information from './module/data/branch_information'
import room_accomodation from './module/data/room_accomodation'
import room_accomodation_history from './module/data/room_accomodation_history'
import auto_name from './module/data/auto_name'
import dayong from './module/data/dayong'
import dayong_fund from './module/data/dayong_fund'
import others_payment from './module/data/others_payment'
import gas_slip_po from './module/data/gas_slip_po'
import transmital_or from './module/data/transmital_or'
import transaction_months_history from './module/data/transaction_months_history'
import chapel_fund from './module/data/chapel_fund'
import chapel_fund_particulars from './module/data/chapel_fund_particulars'
import chapel_fund_liquidation from './module/data/chapel_fund_liquidation'
import gas_slip_liquidation from './module/data/gas_slip_liquidation'
import chapel_manager_incentives from './module/data/chapel_manager_incentives'
import deceased_client_refund from './module/data/deceased_client_refund'
import request_for_print_contract from './module/data/request_for_print_contract'
import government_assistances from './module/data/government_assistances'
import transmital_kmf from './module/data/transmital_kmf'
import db_file from './module/data/db_file'
import officers from './module/data/officers'


const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'GGC-CHAP',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    transaction_months,
    deceased_client,
    casket_inventory,
    chapel_viewing_type,
    staff,
    kaagapay,
    bank_depository,
    deposit_slip,
    payments,
    service_payment,
    series_kmf,
    kaagapay_payment,
    casket_factory,
    request_casket,
    report_casket,
    branch_information,
    room_accomodation,
    room_accomodation_history,
    auto_name,
    dayong,
    dayong_fund,
    others_payment,
    gas_slip_po,
    transmital_or,
    transaction_months_history,
    chapel_fund,
    chapel_fund_particulars,
    chapel_fund_liquidation,
    gas_slip_liquidation,
    chapel_manager_incentives,
    deceased_client_refund,
    request_for_print_contract,
    government_assistances,
    transmital_kmf,
    db_file,
    officers,
  },
})
