<template>
  <div>
    <v-list-item
      @click="is_selected"
      link
      class="vertical-nav-menu-link"
      v-bind="$attrs"
      active-class="bg-gradient-primary white--text"
    >
      <v-list-item-icon>
        <v-icon :class="{ 'alternate-icon-small': !icon }" class="mx-auto">
          {{ icon || alternateIcon }}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
    </v-list-item>

    <v-dialog v-model="dialog_show" persistent scrollable max-width="400">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">Kaagapay Form</h4></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid_form" lazy-validation>
              <v-flex xs12>
                <v-layout column wrap>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="count"
                      label="How Many Pages Do you Print?"
                      class="mx-2"
                      :max="10"
                      readonly
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="saving === false">
            <v-btn class="mx-1" color="primary" @click="print_kaagapay_form()">Print</v-btn>
            <v-btn class="mx-1" color="error" @click="close_dialog">Close</v-btn>
          </div>
          <div v-else-if="saving === true">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiCheckboxBlankCircleOutline} from '@mdi/js'
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['year', 'branch_id', 'month_of', 'branch_address', 'branch_contact_no', 'branch']),
    ...mapGetters('system_data', ['company_logo']),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    to_do: String,
  },
  setup() {
    return {
      alternateIcon: mdiCheckboxBlankCircleOutline,
    }
  },
  data() {
    return {
      dialog_show: false,
      valid_form: false,
      count: 10,
      saving: false,
    }
  },
  methods: {
    ...mapActions('transmital_kmf', ['get_last_print_kmf', 'save_transmital_kmf']),
    is_selected() {
      this.dialog_show = true
    },
    close_dialog() {
      this.dialog_show = false
    },
    print_kaagapay_form() {
      this.saving = true
      const data = new FormData()
      data.append('branch_id', this.branch_id)
      data.append('extension', moment().format('YYYY'))
      this.get_last_print_kmf(data).then(response => {
        var retreive_last_po = parseInt(response.data.end)
        if (response.data === '') {
          retreive_last_po = 0
        }

        this.save_transmital_kmf({
          branch_id: this.branch_id,
          start: retreive_last_po + 1,
          end: retreive_last_po + this.count,
          extension: moment().format('YYYY'),
          remarks: '',
          month_of: this.month_of,
        })
          .then(() => {
            this.saving = false

            var imgData = this.company_logo
            var po_no = 0

            let page_array = []
            var last_po = retreive_last_po
            for (var pages = 1; pages <= this.count; pages++) {
              last_po = last_po + 1
              po_no = last_po + '-' + moment().format('YYYY')
              page_array.push(
                [
                  {
                    columns: [
                      {image: imgData, width: 40, height: 40, style: 'logo'},
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          {text: this.branch_address, style: 'subheader'},
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  '__________________________________________________________________________________________________',
                  {
                    stack: [
                      {
                        columns: [
                          {text: 'Branch: ' + this.branch, style: {fontSize: 9,}},
                          {
                            text: 'KAAGAPAY DISCOUNT FORM',
                            style: {
                              fontSize: 10,
                              alignment: 'center',
                              bold: true,
                              color: 'blue',
                            },
                          },
                          {
                            text: 'KDF No.: ' + po_no,
                            style: {
                              margin: [0, 2, 0, 2],
                              fontSize: 8,
                              alignment: 'right',
                              color: 'red',
                              bold: true,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  '__________________________________________________________________________________________________',
                  {
                    columns: [
                      {text: 'I. NAME', style: {fontSize: 10}},
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '______________________________'},
                      {text: '______________________________'},
                      {text: '______________________________'},
                    ],
                  },
                  {
                    columns: [
                      {text: 'LAST NAME', style: 'main_info'},
                      {text: 'FIRST NAME', style: 'main_info'},
                      {text: 'MIDDLE NAME', style: 'main_info'},
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {
                        text: 'II. COMPLETE ADDRESS: __________________________________________________________________________________________',
                        style: {fontSize: 10}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {
                        text: 'III. DATE OF BIRTH: _______________________ AGE: __________',
                        style: {fontSize: 10}
                      },
                      {
                        text: 'IV. RELIGION: _____________________________',
                        style: {fontSize: 10}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '______________________________'},
                      {text: '______________________________'},
                      {text: '______________________________'},
                    ],
                  },
                  {
                    columns: [
                      {text: 'V. GENDER', style: {fontSize: 10}},
                      {text: 'VI. STATUS', style: {fontSize: 10}},
                      {text: 'VII. OCCUPATION', style: {fontSize: 10}},
                    ],
                  },
                  {
                    stack: [
                      {text: 'VIII. BENEFITS', style: {fontSize: 10}},
                      {
                        style: {alignment: 'justify', fontSize: 7},
                        ul: [
                          'The Holder - 30% Discount to all types of service',
                          'The Relatives - 20% Discount to all types of service',
                          'The Non-Relatives - 10% Discount to all types of service',
                        ],
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '____________________________________', style: {fontSize: 12}},
                      {text: '____________________________________', style: {fontSize: 12}},
                    ],
                  },
                  {
                    columns: [
                      {text: 'WITNESSED BY CHAPEL REPRESENTATIVE', style: {fontSize: 9}},
                      {text: 'SIGNATURE OVER PRINTED NAME', style: {fontSize: 9}},
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Kaagapay Discount is not a membership program, but a discount voucher created specifically, to provide benefits to the holder.',
                        style: {alignment: 'justify', fontSize: 7},
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        columns: [
                          {text: '', style: 'main_info'},
                          {
                            text: 'ACKNOWLEDGEMENT RECEIPT',
                            style: {
                              fontSize: 10,
                              alignment: 'center',
                              bold: true,
                              color: '#f7e307',
                            },
                          },
                          {text: ''},
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Date: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Received From: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Address: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'The Sum of THREE HUNDRED FIFTY PESOS ONLY (Php 350.00)',
                        style: {fontSize: 7}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '__________________________________________', style: {fontSize: 10}},
                    ],
                  },
                  {
                    columns: [
                      {text: 'Signature over Printed Name', style: {fontSize: 8}},
                      {
                        text: ' ',
                      },
                      {
                        table: {
                          body: [
                            [
                              {
                                fillColor: '#f7e307',
                                stack: [
                                  {
                                    text: 'GOODLIFE COPY',
                                    alignment: 'right',
                                    style: {fontSize: 10, color: 'black'}
                                  },
                                ],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                  '\n',
                  '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _',
                  '\n',

                  {
                    columns: [
                      {image: imgData, width: 40, height: 40, style: 'logo'},
                      {
                        stack: [
                          'GOODLIFE MEMORIAL CHAPEL CO.',
                          {text: this.branch_address, style: 'subheader'},
                          {
                            text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                            style: 'subheader',
                          },
                        ],
                        style: 'header',
                      },
                    ],
                  },
                  '__________________________________________________________________________________________________',
                  {
                    stack: [
                      {
                        columns: [
                          {text: 'Branch: ' + this.branch, style: {fontSize: 9,}},
                          {
                            text: 'KAAGAPAY DISCOUNT FORM',
                            style: {
                              fontSize: 10,
                              alignment: 'center',
                              bold: true,
                              color: 'blue',
                            },
                          },
                          {
                            text: 'KDF No.: ' + po_no,
                            style: {
                              margin: [0, 2, 0, 2],
                              fontSize: 8,
                              alignment: 'right',
                              color: 'red',
                              bold: true,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  '__________________________________________________________________________________________________',
                  {
                    columns: [
                      {text: 'I. NAME', style: {fontSize: 10}},
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '______________________________'},
                      {text: '______________________________'},
                      {text: '______________________________'},
                    ],
                  },
                  {
                    columns: [
                      {text: 'LAST NAME', style: 'main_info'},
                      {text: 'FIRST NAME', style: 'main_info'},
                      {text: 'MIDDLE NAME', style: 'main_info'},
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {
                        text: 'II. COMPLETE ADDRESS: __________________________________________________________________________________________',
                        style: {fontSize: 10}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {
                        text: 'III. DATE OF BIRTH: _______________________ AGE: __________',
                        style: {fontSize: 10}
                      },
                      {
                        text: 'IV. RELIGION: _____________________________',
                        style: {fontSize: 10}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '______________________________'},
                      {text: '______________________________'},
                      {text: '______________________________'},
                    ],
                  },
                  {
                    columns: [
                      {text: 'V. GENDER', style: {fontSize: 10}},
                      {text: 'VI. STATUS', style: {fontSize: 10}},
                      {text: 'VII. OCCUPATION', style: {fontSize: 10}},
                    ],
                  },
                  {
                    stack: [
                      {text: 'VIII. BENEFITS', style: {fontSize: 10}},
                      {
                        style: {alignment: 'justify', fontSize: 7},
                        ul: [
                          'The Holder - 30% Discount to all types of service',
                          'The Relatives - 20% Discount to all types of service',
                          'The Non-Relatives - 10% Discount to all types of service',
                        ],
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '____________________________________', style: {fontSize: 12}},
                      {text: '____________________________________', style: {fontSize: 12}},
                    ],
                  },
                  {
                    columns: [
                      {text: 'WITNESSED BY CHAPEL REPRESENTATIVE', style: {fontSize: 9}},
                      {text: 'SIGNATURE OVER PRINTED NAME', style: {fontSize: 9}},
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Kaagapay Discount is not a membership program, but a discount voucher created specifically, to provide benefits to the holder.',
                        style: {alignment: 'justify', fontSize: 7},
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        columns: [
                          {text: '', style: 'main_info'},
                          {
                            text: 'ACKNOWLEDGEMENT RECEIPT',
                            style: {
                              fontSize: 10,
                              alignment: 'center',
                              bold: true,
                              color: '#0039f5',
                            },
                          },
                          {text: ''},
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Date: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Received From: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Address: __________________________________________',
                        style: {fontSize: 8}
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'The Sum of THREE HUNDRED FIFTY PESOS ONLY (Php 350.00)',
                        style: {fontSize: 7}
                      },
                    ],
                  },
                  '\n',
                  {
                    columns: [
                      {text: '__________________________________________', style: {fontSize: 10}},
                    ],
                  },
                  {
                    columns: [
                      {text: 'Signature over Printed Name', style: {fontSize: 8}},
                      {
                        text: ' ',
                      },
                      {
                        table: {
                          body: [
                            [
                              {
                                fillColor: '#0039f5',
                                stack: [
                                  {
                                    text: 'CLIENT COPY',
                                    alignment: 'right',
                                    style: {fontSize: 10, color: 'black'}
                                  },
                                ],
                              },
                            ],
                          ],
                        },
                      },
                    ], pageBreak: 'after'
                  },
                ],
              )
            }
            //call pdfmake
            var pdfMake = require('pdfmake/build/pdfmake.js')
            if (pdfMake.vfs == undefined) {
              var pdfFonts = require('pdfmake/build/vfs_fonts.js')
              pdfMake.vfs = pdfFonts.pdfMake.vfs
            }
            var docDefinition = {
              pageSize: {
                width: 612,
                height: 936,
              },
              pageOrientation: 'portrait',
              content: [
                page_array
              ],
              styles: {
                header: {
                  fontSize: 12,
                  bold: true,
                  alignment: 'left',
                  margin: [6, 6, 0, 5], //[left, top, right, bottom]
                },
                subheader: {
                  fontSize: 8,
                },
                logo: {
                  alignment: 'center',
                  margin: [0, 0, 0, 0], //[left, top, right, bottom]
                },
                printed_label: {
                  alignment: 'right',
                  fontSize: 7,
                  margin: [0, 0, 0, 0], //[left, top, right, bottom]
                },
                main_data: {
                  margin: [0, 2, 0, 2],
                  fontSize: 7,
                  bold: true,
                  alignment: 'left',
                },
                main_data_2: {
                  margin: [0, 2, 0, 2],
                  fontSize: 6,
                  bold: true,
                  alignment: 'left',
                },
                main_info: {
                  margin: [0, 2, 0, 2],
                  fontSize: 7,
                  alignment: 'left',
                },
              },
            }
            pdfMake.createPdf(docDefinition).open()
            this.close_dialog()
          })
          .catch(error => {
            console.log(error)
            this.saving = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '~vuetify/src/styles/styles.sass';

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
