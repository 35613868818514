import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/new-deceased',
    name: 'new-deceased',
    component: () => import('@/views/register/NewDeceased'),
    meta: {
      title: 'New Deceased',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/payments/Payments'),
    meta: {
      title: 'Payments',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-deposit-slip',
    name: 'new-deposit-slip',
    component: () => import('@/views/others/DepositSlips'),
    meta: {
      title: 'Deposit Slips',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-kaagapay',
    name: 'new-kaagapay',
    component: () => import('@/views/register/NewKaagapay'),
    meta: {
      title: 'New Kaagapay',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-request-casket',
    name: 'new-request-casket',
    component: () => import('@/views/request/RequestCasket'),
    meta: {
      title: 'New Request Casket',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/approved-request-casket',
    name: 'approved-request-casket',
    component: () => import('@/views/admin_interface/ApprovedRequestCasket'),
    meta: {
      title: 'Approved Request Casket',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-callers',
    name: 'new-callers',
    component: () => import('@/views/register/NewCallers'),
    meta: {
      title: 'New Callers',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-assigned-room-accomodation',
    name: 'new-assigned-room-accomodation',
    component: () => import('@/views/register/AssignedRoomAccomodation'),
    meta: {
      title: 'Assigned Room Accomodation',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-auto-name',
    name: 'new-auto-name',
    component: () => import('@/views/register/AutoName'),
    meta: {
      title: 'Auto Name',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/new-dayong-name',
    name: 'new-dayong-name',
    component: () => import('@/views/register/Dayong'),
    meta: {
      title: 'Dayong Name',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/search-deceased-client',
    name: 'search-deceased-client',
    component: () => import('@/views/search_interface/SearchDeceasedClient'),
    meta: {
      title: 'Search Deceased',
      requiresAuth: true,
    },
  },
  {
    path: '/search-kaagapay-client',
    name: 'search-kaagapay-client',
    component: () => import('@/views/search_interface/SearchKaagapay'),
    meta: {
      title: 'Search Kaagapay',
      requiresAuth: true,
    },
  },
  {
    path: '/remittances',
    name: 'remittances',
    component: () => import('@/views/view_interface/Remittances'),
    meta: {
      title: 'Remittances',
      requiresAuth: true,
    },
  },
  {
    path: '/assign-OR',
    name: 'assign-OR',
    component: () => import('@/views/others/OrSeries'),
    meta: {
      title: 'Assigned OR',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/assign-OR-kaagapay',
    name: 'assign-OR-kaagapay',
    component: () => import('@/views/others/KaagapayOrSeries'),
    meta: {
      title: 'Kaagapay Assigned OR',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/re-assign-OR',
    name: 're-assign-OR',
    component: () => import('@/views/others/ORReAssign'),
    meta: {
      title: 'Re-Assigned OR',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/bank-transaction-report',
    name: 'bank-transaction-report',
    component: () => import('@/views/reports_interface/BankTransaction'),
    meta: {
      title: 'Bank Transaction',
      requiresAuth: true,
    },
  },
  {
    path: '/chapel-fund-liquidations',
    name: 'chapel-fund-liquidations',
    component: () => import('@/views/liquidations/ChapelFundLiquidation'),
    meta: {
      title: 'Chapel Fund Liquidations',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/chapel-fund-request',
    name: 'chapel-fund-request',
    component: () => import('@/views/request/RequestChapelFund'),
    meta: {
      title: 'Request Chapel Fund',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/gas-slip-liquidation',
    name: 'gas-slip-liquidation',
    component: () => import('@/views/liquidations/GasSlipLiquidation'),
    meta: {
      title: 'Gas Slip Liquidation',
      requiresAuth: true,
      requiresChapelStaff: true,
    },
  },
  {
    path: '/callers-income',
    name: 'callers-income',
    component: () => import('@/views/reports_interface/CallersIncome'),
    meta: {
      title: 'Callers Income',
      requiresAuth: true,
    },
  },
  {
    path: '/chapel-fund-summary',
    name: 'chapel-fund-summary',
    component: () => import('@/views/reports_interface/chapel_fund/ChapelFundSummary'),
    meta: {
      title: 'Callers Fund Summary',
      requiresAuth: true,
    },
  },
  {
    path: '/list-of-deceased',
    name: 'list-of-deceased',
    component: () => import('@/views/reports_interface/ListOfDeceased'),
    meta: {
      title: 'List Of Deceased',
      requiresAuth: true,
    },
  },
  {
    path: '/list-of-kaagapay',
    name: 'list-of-kaagapay',
    component: () => import('@/views/reports_interface/ListOfKaagapay'),
    meta: {
      title: 'List Of Kaagapay',
      requiresAuth: true,
    },
  },
  {
    path: '/summary-collection-report',
    name: 'summary-collection-report',
    component: () => import('@/views/reports_interface/SummaryCollection'),
    meta: {
      title: 'Summary Collection Report',
      requiresAuth: true,
    },
  },
  {
    path: '/request-casket-report',
    name: 'request-casket-report',
    component: () => import('@/views/reports_interface/RequestCasketReport'),
    meta: {
      title: 'Request Casket Report',
      requiresAuth: true,
    },
  },
  {
    path: '/unpaid-service',
    name: 'unpaid-service',
    component: () => import('@/views/reports_interface/UnpaidService'),
    meta: {
      title: 'Unpaid Service',
      requiresAuth: true,
    },
  },
  {
    path: '/unpaid-service-data',
    name: 'unpaid-service-data',
    component: () => import('@/views/reports_interface/UnpaidServiceData'),
    meta: {
      title: 'Unpaid Service Data',
      requiresAuth: true,
    },
  },
  {
    path: '/gas-slip-liquidation-report',
    name: 'gas-slip-liquidation-report',
    component: () => import('@/views/reports_interface/GasSlipLiquidationReport'),
    meta: {
      title: 'Gas Slip Liquidation Report',
      requiresAuth: true,
    },
  },
  {
    path: '/chapel-manager-incentives',
    name: 'chapel-manager-incentives',
    component: () => import('@/views/reports_interface/cm_incentives/ChapelManagerIncentives'),
    meta: {
      title: 'Chapel Manager Incentives',
      requiresAuth: true,
      requiresAdmin: true,
      requiresChapelManager: true,
    },
  },
  {
    path: '/list-of-deceased-with-refund',
    name: 'list-of-deceased-with-refund',
    component: () => import('@/views/admin_interface/deceased_with_refunds/ListofDeceasedWithRefund'),
    meta: {
      title: 'List of Deceased With Refund',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-summary-collection-report',
    name: 'admin-summary-collection-report',
    component: () => import('@/views/admin_interface/AdminSummaryCollection'),
    meta: {
      title: 'AdminSummaryCollection',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-register-users',
    name: 'admin-register-users',
    component: () => import('@/views/admin_interface_special/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-deceased-with-refund-reports',
    name: 'list-of-deceased-with-refund-reports',
    component: () => import('@/views/admin_interface/deceased_with_refunds/ListofDeceasedWithRefundReport'),
    meta: {
      title: 'List of Deceased With Refund Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/or-monitoring',
    name: 'or-monitoring',
    component: () => import('@/views/others/OrMonitoring'),
    meta: {
      title: 'Or Monitoring',
      requiresAuth: true,
    },
  },
  {
    path: '/request-for-print',
    name: 'request-for-print',
    component: () => import('@/views/request/RequestContractPrint'),
    meta: {
      title: 'Request Contract Printing',
      requiresAuth: true,
    },
  },
  {
    path: '/approve-request-for-print',
    name: 'approve-request-for-print',
    component: () => import('@/views/admin_interface/ApprovedRequestContractPrint'),
    meta: {
      title: 'Approve Request Contract Printing',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/search-remittances',
    name: 'search-remittances',
    component: () => import('@/views/search_interface/SearchRemittances'),
    meta: {
      title: 'Search Remittances',
      requiresAuth: true,
    },
  },
  {
    path: '/casket-inventory',
    name: 'casket-inventory',
    component: () => import('@/views/view_interface/CasketInvetoy'),
    meta: {
      title: 'Casket Inventories',
      requiresAuth: true,
    },
  },
  {
    path: '/book-of-accounts',
    name: 'book-of-accounts',
    component: () => import('@/views/view_interface/BookOfAccounts'),
    meta: {
      title: 'Book Of Accounts',
      requiresAuth: true,
    },
  },
  {
    path: '/cm-incentives-summary',
    name: 'cm-incentives-summary',
    component: () => import('@/views/reports_interface/cm_incentives/CmIncentivesSummary'),
    meta: {
      title: 'Cm Incentives Summary',
      requiresAuth: true,
    },
  },
  {
    path: '/chapel-fund-particulars',
    name: 'chapel-fund-particulars',
    component: () => import('@/views/reports_interface/chapel_fund/CFByParticulars'),
    meta: {
      title: 'CF By Particulars',
      requiresAuth: true,
    },
  },
  {
    path: '/new-sales-agent',
    name: 'new-sales-agent',
    component: () => import('@/views/register/NewSalesAgent'),
    meta: {
      title: 'New Sales Agent',
      requiresAuth: true,
    },
  },
  {
    path: '/approved-for-new-month',
    name: 'approved-for-new-month',
    component: () => import('@/views/admin_interface_special/ApprovedForNewMonth'),
    meta: {
      title: 'Approved For New Month',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-register-branch',
    name: 'admin-register-branch',
    component: () => import('@/views/admin_interface_special/NewBranch'),
    meta: {
      title: 'New Branch',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/register-collector',
    name: 'register-collector',
    component: () => import('@/views/admin_interface_special/NewCollector'),
    meta: {
      title: 'New Collector',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-register-officer',
    name: 'admin-register-officer',
    component: () => import('@/views/admin_interface_special/NewOfficer'),
    meta: {
      title: 'New Officers',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/admin-bank-tally',
    name: 'admin-bank-tally',
    component: () => import('@/views/admin_interface_special/BankTally'),
    meta: {
      title: 'Bank Tally',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-or-batch',
    name: 'load-or-batch',
    component: () => import('@/views/admin_interface_special/ORBatch'),
    meta: {
      title: 'Load OR Batch',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/transmittal-load-or-batch',
    name: 'transmittal-load-or-batch',
    component: () => import('@/views/admin_interface_special/TransmitalOR'),
    meta: {
      title: 'Transmital OR',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/rcm-incentives',
    name: 'rcm-incentives',
    component: () => import('@/views/reports_interface/officer_incentives/OfficerIncentives'),
    meta: {
      title: 'Officer Incentives',
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
